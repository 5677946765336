// App.js
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './Pages/Home'
import Header from './Components/Header'
import Footer from './Components/Footer'
import PeerAdvocasyPage from './Pages/PeerAdvocasy'
import TransitionPage from './Pages/Transition'
import HealthPage from './Pages/Health'
import EmploymentPage from './Pages/Employment'
import ScrollToTop from './Components/ScrollToTop'
import AboutUs from './Pages/About'
import SignIn from './Pages/Auth/signIn'
import './index.css'
import SignUp from './Pages/Auth/signUp'

function App () {
  return (
    <div className='App'>
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/peeradvocacy' element={<PeerAdvocasyPage />} />
          <Route path='/transition' element={<TransitionPage />} />
          <Route path='/health' element={<HealthPage />} />
          <Route path='/employment' element={<EmploymentPage />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/signin' element={<SignIn />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

export default App
