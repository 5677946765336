// ImageSlider.js
import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import './index.css'
import slider1 from '../../assets/images/slider_1.jpg'
import slider2 from '../../assets/images/slider_2.jpg'
import slider3 from '../../assets/images/slider_3.jpg'
import slider4 from '../../assets/images/slider_4.jpg'
import slider5 from '../../assets/images/slider_5.jpg'
import slider6 from '../../assets/images/slider_6.png'

const images = [
  { src: slider4, text: 'Welcome to My360Tribe', btnText: 'GET STARTED' },
  { src: slider5, text: 'WHAT WE DO', btnText: 'LEARN MORE' },
  { src: slider6, text: 'CONTACT US', btnText: 'CONTACT NOW' }
]

export default function ImageSlider () {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length)
    }, 7000) // Change slide every 3 seconds

    return () => clearInterval(interval) // Clear interval on component unmount
  }, [])

  return (
    <div className='slider-container'>
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image.src})` }}
        >
          {index === currentIndex && (
            <>
              <motion.div
                className='slide-text'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
              >
                {image.text}
              </motion.div>

              <motion.button
                className='slide-button'
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                onClick={() => window.location.href = 'https://dashboard.my360tribe.org/signup'}
              >
                {image.btnText}
              </motion.button>
            </>
          )}
        </div>
      ))}
    </div>
  )
}
