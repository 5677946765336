import React from 'react'
import ServicesPageTemplate from '../../Components/ServisesPageTemplate'
import { useNavigate } from 'react-router-dom'
import Page from '../../Components/Page'

const TransitionPage = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  const tabsContent = [
    {
      key: '1',
      label: 'Education',
      content:
        'Want to go back to school, learn a new trade or obtain a civilian certification? We have resources and a referral network to introduce our operators to different educational programming across the country.'
    },
    {
      key: '2',
      label: 'VA Program Assistance',
      content: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance.`
    }
  ]

  return (
    // <Page>
    <ServicesPageTemplate
      heading='Transition'
      paragraph='At My360Tribe, we are committed to your health and well-being. Providing referrals and services to accomplish 360-degree warrior care is our mission. Above you will find a listing of our areas of referrals and services as it relates to an operator’s health and well-being.'
      tabsContent={tabsContent}
      onBack={handleBack}
    />
    // </Page>
  )
}

export default TransitionPage
