import React, { useState } from 'react'
import './index.css'
import BlackMainHeading from '../UIKit/BlackHeadingText'
import SMallHeading from '../UIKit/SmallHeadingText'

export default function AboutUsSection () {
  const [activeRole, setActiveRole] = useState(null)

  // Array of roles and descriptions
  const roles = [
    {
      title: 'Peer Ambassador',
      description:
        'Peer Ambassador Network by offering peer to peer support, My360Tribe ensures that operators are helping operators with real life, real time advice and referrals to get the support and help they need.'
    },
    {
      title: 'Advisory Board',
      description:
        'As advocates and ambassadors of the organization and its mission, the advisory board acts as liaison between the special operations community and the organization, providing strategic guidance, organizational planning and execution, and assisting with Service Partners relationships. In addition to these duties the advisory board will also assist with fundraising activities when necessary.'
    },
    {
      title: 'Concierge',
      description:
        'As the first contact point, concierges are the ambassadors of the organization. They must possess a warm, friendly attitude paired with an intensely curious nature and an intense drive to help. Concierges will develop a more robust needs assessment through conversations with the veteran. Concierges are Service Partners experts - responsible for making introductions, tracking and maintaining the relationship between the Service Partners and the veteran'
    },
    {
      title: 'Service Partners',
      description:
        'Our service partners are made up of companies and non-profit organizations dedicated to the success of our SOF operators in the civilian world. It is the dedication and commitment of these entities that help make My360Tribe a success.'
    },
    {
      title: 'Executive Board',
      description:
        'The Executive Board for My360Tribe is made up of Military and Civilian professionals with a deep-rooted passion for and extensive knowledge of the SOF community. We are dedicated to the Operator and their family’s success before, during and after transition from military service.'
    }
  ]

  return (
    <section className='about-us-section'>
      <SMallHeading text={'About Us'} />
      <BlackMainHeading text={'Discover Our Mission'} />

      <div className='roles-grid'>
        {roles.map((role, index) => (
          <div
            key={index}
            className={`role-box ${activeRole === index ? 'active' : ''}`}
            onClick={() => setActiveRole(index)}
          >
            {role.title}
          </div>
        ))}
      </div>

      {activeRole !== null && (
        <div className='role-description'>
          <h3 className='description-heading'>{roles[activeRole].title}</h3>
          <p className='description-text'>{roles[activeRole].description}</p>
        </div>
      )}
    </section>
  )
}
