import React from 'react'
import './index.css'
import Footer from '../Footer'

export default function Page ({ children }) {
  return (
    <div className='page-container'>
      {children}
      {/* <Footer /> */}
    </div>
  )
}
