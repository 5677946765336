import React from 'react'
import Page from '../../Components/Page'
import ImageSlider from '../../Components/ImageSlider'
import Header from '../../Components/Header'
import ImageGrid from '../../Components/ImageGrid'
import OverlayCardSection from '../../Components/ServicesSection'
import AboutUsSection from '../../Components/WhoWeAre'
import WhyWeDoSection from '../../Components/WhyWeDo'
import MeetOurTeam from '../../Components/OurTeam'

export default function HomePage () {
  return (
    <Page>
      <ImageSlider />
      <ImageGrid />
      <AboutUsSection />
      <OverlayCardSection />
      <WhyWeDoSection />
      <MeetOurTeam />
    </Page>
  )
}
