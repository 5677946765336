import React, { useState } from 'react'
import './index.css'
// import img from '../../assets/images/miliary.jpg'
import img from '../../assets/images/what-we-do-2.webp'
import { useNavigate } from 'react-router-dom'

export default function OverlayCardSection () {
  const [activeIndex, setActiveIndex] = useState(0)
  const navigate = useNavigate()

  // Array of card data corresponding to buttons
  const cards = [
    {
      title: 'Peer Advocacy',
      page: '/peeradvocacy',
      description:
        'What sets us apart is our Concierge-level support and extensive, confidential Peer Ambassador network of Operators helping Operators by providing support and guidance to you as a member of My360Tribe. Your Concierge will keep in contact with you and help coordinate contact with the service partners that can assist you in navigating this new life. Click on the tabs above to learn more about the 360-Warrior Care.'
    },
    {
      title: 'Transition',
      page: '/transition',
      description:
        'At My360Tribe, we are committed to your health and well-being. Providing referrals and services to accomplish 360-degree warrior care is our mission. Above you will find a listing of our areas of referrals and services as it relates to an operator’s health and well-being.'
    },
    {
      title: 'Health',
      page: '/health',
      description:
        'At My360Tribe, we are committed to your health and well-being. Providing referrals and services to accomplish 360-degree warrior care is our mission. Above you will find a listing of our areas of referrals and services as it relates to an operator’s health and well-being.'
    },
    {
      title: 'Employment',
      page: '/employment',
      description:
        'My360Tribe has established relationships with service organizations, corporations and businesses to help you expand your network, mentoring opportunities, job services and even starting your own business.'
    }
  ]

  const activeCard = cards[activeIndex]

  return (
    <div className='overlay-card-section'>
      <img src={img} alt='background' style={{ width: '100%' }} />
      <div className='dark-overlay'>
        <div className='services-content-container'>
          <h2 className='heading'>What we do</h2>
          <div className='services-content'>
            <div className='left-section'>
              <div className='services-button-group'>
                {cards.map((card, index) => (
                  <button
                    key={index}
                    className={`service-button ${
                      index === activeIndex ? 'active' : ''
                    }`}
                    onClick={() => setActiveIndex(index)}
                  >
                    {card.title}
                  </button>
                ))}
              </div>
            </div>
            <div className='right-section'>
              <div className='text-card active'>
                <h3 className='card-title'>{activeCard.title}</h3>
                <p className='card-description'>{activeCard.description}</p>
                <button
                  className='learn-more-button'
                  onClick={() => navigate(activeCard.page)}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
