import React from 'react';
import { Tabs } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './index.css';

const ServicesPageTemplate = ({ heading, paragraph, tabsContent, onBack }) => {
  return (
    <div className="page-template">
      <div className="page-header">
        <ArrowLeftOutlined className="back-button" onClick={onBack} />
        <h2 className="page-heading">{heading}</h2>
      </div>
      <p className="page-paragraph">{paragraph}</p>
      <Tabs
        tabBarStyle={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        className="page-tabs"
        centered={false} // Avoid centering for better mobile experience
      >
        {tabsContent.map((tab) => (
          <Tabs.TabPane tab={tab.label} key={tab.key}>
            <div className="tab-content">{tab.content}</div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ServicesPageTemplate;
