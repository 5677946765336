import React from 'react'
import SmallHeading from '../UIKit/SmallHeadingText'
import BlackMainHeading from '../UIKit/BlackHeadingText'
import './index.css'

export default function WhyWeDoSection () {
  return (
    <div className='why-we-do-section'>
      <div style={{ textAlign: 'center' }}>
        <SmallHeading text='Why we do' />
        <BlackMainHeading text='What we do' />
      </div>
      <p className='description-text'>
        My360Tribe exists to support the transitioning Special Operator during
        and after their transition out of the military. Our Tribe is unique in
        that it is comprised of those who have either served in the same
        capacity or has supported the Special Operations Community for over a
        decade and understands the challenges that an Operator faces throughout
        the transition process. With an initial connection to a Concierge and a
        match to one of our Special Operator Peer Ambassadors, the Operator will
        have 360 support for as long as it's needed.
      </p>
    </div>
  )
}
