import React from 'react'
import ServicesPageTemplate from '../../Components/ServisesPageTemplate'
import { useNavigate } from 'react-router-dom'
import Page from '../../Components/Page'

const HealthPage = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  const tabsContent = [
    {
      key: '1',
      label: 'Medical',
      content:
        'We have partnered with organizations and providers committed to the mission of My360Tribe that will ensure you are getting the most appropriate care based on your specific needs.'
    },
    {
      key: '2',
      label: 'Mental Health',
      content: `We want you to thrive and succeed in this next chapter.... We understand the mental and emotional strain of transition and have partnered with a range of service providers to support you and your family during this challenging time..`
    },
    {
      key: '3',
      label: 'Substance Abuse',
      content: `We all know that substance abuse can become a factor in an Operator’s life after transition. The feelings of desperation, isolation, and lack of purpose can manifest into substance abuse and addiction. My360Tribe will provide referrals to the appropriate service partner to help you and your family through this difficult time.`
    },
    {
      key: '4',
      label: 'Mobility',
      content: `Leaving the service with mobility issues can be painful and challenging. We at My360Tribe have a network of service partners that are available to assist you and your family as you learn to live your 'new normal'.`
    },
    {
      key: '5',
      label: 'Sports and Recreation',
      content: `We believe that sports, recreation and physical activity are an integral part of your overall physical and mental health. My360Tribe has partnered with a variety of providers that understand the needs of the transitioning warrior.`
    }
  ]

  return (
    // <Page>
    <ServicesPageTemplate
      heading='Health'
      paragraph='At My360Tribe, we are committed to your health and well-being. Providing referrals and services to accomplish 360-degree warrior care is our mission. Above you will find a listing of our areas of referrals and services as it relates to an operator’s health and well-being.'
      tabsContent={tabsContent}
      onBack={handleBack}
    />
    // </Page>
  )
}

export default HealthPage
