// ImageGrid.js
import React from 'react'
import './index.css' // CSS for the grid layout
import img1 from '../../assets/images/grid_1.jpg'
import img2 from '../../assets/images/grid_2.jpg'
import img3 from '../../assets/images/grid_3.jpg'
import img4 from '../../assets/images/grid_4.png'
import img5 from '../../assets/images/grid_5.webp'
import img6 from '../../assets/images/grid_6.webp'
import img7 from '../../assets/images/slider_5.jpg'
import img8 from '../../assets/images/grid_7.png'
import img9 from '../../assets/images/grid_8.jpg'
// #1b181a
const images = [
  {
    src: img8,
    title: 'Operator',
    description:
      '"We are dedicated to serving those U.S. Special Operations members that were assessed, selected, trained, qualified, and served honorably as Operators at any of the U.S. Special Operations Commands, specifically, the U.S. Army Special Operations Command, U.S. Air Force Special Operations Command, U.S. Marine Forces Special Operations Command, Naval Special Warfare Command, and Joint Special Operations Command.'
  },
  {
    src: img5,
    title: 'Concierge',
    description:
      'Are you passionate about making a difference in the life of a transitioning Operator? Are you an Operator who has already transitioned and wants to give back to your Community? Apply here to be a Concierge.'
  },
  {
    src: img6,
    title: 'Peer Ambassador',
    description:
      'Are you a SOF Operator who has been out of the military for more than a year and wants to support a newly transitioning Operator?'
  },
  {
    src: img7,
    title: 'Service Partners',
    description:
      'We are always looking to build new partnerships that can support transitioning warriors. Please click here if you would like to partner with My360Tribe or to learn more'
  }
]

export default function ImageGrid () {
  return (
    <div className='section-container'>
      <div className='small-heading'>Take a path with purpose</div>
      <div className='main-section-heading'>Be part of something bigger</div>
      <div className='main-section-heading-part'>than yourself</div>
      <div className='image-grid'>
        {images.map((image, index) => (
          <div key={index} className='image-item'>
            <img
              src={image.src}
              alt={`Image ${index + 1}`}
              className='grid-image'
            />
            <h3 className='image-text'>{image.title}</h3>
            <div className='bottom-overlay'>
              <div style={{ width: '90%' }}>
                <p className='overlay-description'>{image.description}</p>
              </div>
              <button className='join-button'>Join Now</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
