import React from 'react'
import ServicesPageTemplate from '../../Components/ServisesPageTemplate'
import { useNavigate } from 'react-router-dom'
import Page from '../../Components/Page'

const PeerAdvocasyPage = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  const tabsContent = [
    {
      key: '1',
      label: 'Relocation',
      content:
        'Relocating can be stressful and deciding where to live and moving can be daunting. We have resources available around the country to help you and your family make the best decisions on where to settle and begin your life anew.'
    },
    {
      key: '2',
      label: 'Legal Services',
      content:
        'There will be times when you may require legal representation or assistance. My360Tribe can provide you with referrals to established law firms and attorneys.'
    },
    {
      key: '3',
      label: 'Financial Support',
      content:
        'My360Tribe can provide assistance in identifying a reputable financial institution, financial planner or other financial resources.'
    },
    {
      key: '4',
      label: 'Family/Legal Support',
      content: `Can divorce fall under "Legal" so we don't need to get that specific as "Legal" can cover all areas needed? Text: "We know it isn't just the Operator who is transitioning, but the whole family. Resources to support the family and individual family members are available, including family counseling, couples and family retreats`
    },
    { key: '5', label: 'Mentorship', content: 'No content yet' }
  ]

  return (
    // <Page>
      <ServicesPageTemplate
        heading='Peer Advocacy'
        paragraph='What sets us apart is our Concierge-level support and extensive, confidential Peer Ambassador network of Operators helping Operators by providing support and guidance to you as a member of My360Tribe. Your Concierge will keep in contact with you and help coordinate contact with the service partners that can assist you in navigating this new life. Click on the tabs above to learn more about the 360-Warrior Care.'
        tabsContent={tabsContent}
        onBack={handleBack}
      />
    // </Page>
  )
}

export default PeerAdvocasyPage
