import React from 'react'
import './index.css'
import logo from '../../assets/images/logo_white.png'
import footerLogo from '../../assets/images/footer-logo.png'
import partner from '../../assets/images/footer_partner.jpeg'
import { FaLinkedin } from 'react-icons/fa6'

const Footer = () => {
  return (
    <div>
      <footer className='footer'>
        {/* Left Column */}
        <div className='footer-column'>
          <img src={footerLogo} alt='Logo' className='footer-logo' />
          <h3 className='footer-heading'>Address</h3>
          <p className='footer-text'>
            My360Tribe
            <br />
            128 Adrian Avenue, <br />
            South San Francisco, CA 94080
          </p>
          <h3 className='footer-heading'>Contact us</h3>
          <a
            href='https://linkedin.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaLinkedin />
          </a>
        </div>

        {/* Middle Column */}
        <div className='footer-column'>
          <img
            src={partner}
            alt='Service Partner Logo'
            className='footer-partner-logo'
          />
          <h3 className='footer-heading'>Terms and Conditions</h3>
        </div>

        {/* Right Column */}
        <div className='footer-column'>
          <h3 className='footer-heading'>Get Involved</h3>
          <div className='footer-buttons'>
            <button className='footer-button' onClick={() => window.location.href = 'https://dashboard.my360tribe.org/signup'}>Volunteer</button>
            <button className='footer-button' onClick={() => window.location.href = 'https://dashboard.my360tribe.org/signup'}>Partner</button>
            <button className='footer-button' onClick={() => window.location.href = 'https://dashboard.my360tribe.org/signup'}>Collaborate</button>
            <button className='footer-donate-button' onClick={() => window.location.href = 'https://merchant.reverepayments.dev/forms/35SqPodk7aSXSW6tE3N9-ToxPhV_kGW1sTEVX3iM3X8=/'}>Donate</button>
          </div>
        </div>
      </footer>
      <div
        style={{ color: 'white', backgroundColor: '#222', textAlign: 'center' }}
      >
        Copyright © 2024 My360Tribe. All rights reserved.
      </div>
    </div>
  )
}

export default Footer
