import React from 'react'
import ServicesPageTemplate from '../../Components/ServisesPageTemplate'
import { useNavigate } from 'react-router-dom'
import Page from '../../Components/Page'

const EmploymentPage = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  const tabsContent = [
    {
      key: '1',
      label: 'Job Services',
      content:
        'My360Tribe knows job searching can be a full time job in and of itself. We have a network of partners and providers who are well versed in resume development, conducting mock interviews, building a strong LinkedIn profile as well as other activities critical in identifying your new mission.'
    },
    {
      key: '2',
      label: 'Talent Acquisition/Recruiting',
      content: `This service provided by our partners is for those of you looking to elevate your game and those that bring a strong sense of leadership and adaptability to the civilian workforce. We have partners who can help guide you and prepare you for your next leadership role.`
    },
    {
      key: '3',
      label: 'Entrepreneurship',
      content: `If working for someone else...We have resources and partners available to help support and guide you as you build your own company, from concept to business case, understanding areas of risk and how to successful raise money.`
    },
    {
      key: '4',
      label: 'Mentorship',
      content: `My360Tribe is comprised of a team of mentors and advisors…We have a vast pool of professional mentors and coaches who understand your world, the path you walk and bring a wealth of experience to the many facets of navigating this next phase of your life.`
    }
  ]

  return (
    // <Page>
    <ServicesPageTemplate
      heading='Employment'
      paragraph='My360Tribe has established relationships with service organizations, corporations and businesses to help you expand your network, mentoring opportunities, job services and even starting your own business.'
      tabsContent={tabsContent}
      onBack={handleBack}
    />
    // </Page>
  )
}

export default EmploymentPage
