import React, { useState } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { Drawer } from 'antd'
import './index.css'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/logo_white.png'

export default function Header () {
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()

  const toggleMenu = link => {
    navigate(link)
    setMenuOpen(!menuOpen)
  }

  return (
    <header className='header'>
      <div className='logo'>
        <img src={logo} alt='logo' style={{ width: '100%' }} onClick={() => navigate('/')}/>
      </div>
      <nav className='nav-buttons'>
        <button className='nav-button' onClick={() => navigate('/')}>
          HOME
        </button>
        <button className='nav-button' onClick={() => navigate('/about')}>
          ABOUT
        </button>
        {/* <button className='nav-button' onClick={() => navigate('/signin')}>
          SIGN IN
        </button> */}
        <button 
          className="nav-button" 
          onClick={() => window.location.href = 'https://dashboard.my360tribe.org/login'}
        >
          SIGN IN
        </button>
        <button className='nav-button donate-btn' onClick={() => window.location.href = 'https://merchant.reverepayments.dev/forms/35SqPodk7aSXSW6tE3N9-ToxPhV_kGW1sTEVX3iM3X8=/'}>DONATE</button>
      </nav>
      <MenuOutlined
        className='hamburger-menu'
        onClick={() => setMenuOpen(!menuOpen)}
      />
      <Drawer
        placement='right'
        onClose={() => setMenuOpen(!menuOpen)}
        closeIcon
        open={menuOpen}
        width={250}
        styles={{
          body: { padding: 0 },
          header: { background: '#fff' }
        }}
      >
        <div className='drawer-buttons'>
          <button className='nav-button' onClick={() => toggleMenu('/')}>
            HOME
          </button>
          <button className='nav-button' onClick={() => toggleMenu('/about')}>
            ABOUT
          </button>
          {/* <button className='nav-button' onClick={toggleMenu}>
            CONTACT
          </button> */}
          <button className='nav-button' onClick={() => window.location.href = 'https://dashboard.my360tribe.org/login'}>
            SIGN IN
          </button>
          <button
            className='nav-button donate-btn'
            // onClick={() =>
            //   toggleMenu(
            //     'https://merchant.reverepayments.dev/forms/35SqPodk7aSXSW6tE3N9-ToxPhV_kGW1sTEVX3iM3X8=/'
            //   )
            // }
            onClick={() => window.location.href = 'https://merchant.reverepayments.dev/forms/35SqPodk7aSXSW6tE3N9-ToxPhV_kGW1sTEVX3iM3X8=/'}
          >
            DONATE
          </button>
        </div>
      </Drawer>
    </header>
  )
}
