import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './index.css'
import { signInApi } from '../../Apis'

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  // Update form values
  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  // Validate form
  const validateForm = () => {
    const newErrors = {}
    if (!formData.email) {
      newErrors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address'
    }

    if (!formData.password) {
      newErrors.password = 'Password is required'
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  // Handle form submission
  const handleSubmit = async e => {
    e.preventDefault()
    if (!validateForm()) return

    setIsSubmitting(true)
    try {
      const response = await fetch(signInApi, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      if (!response.ok) {
        const errorData = await response.json()
        setErrors({ api: errorData.message || 'Something went wrong' })
      } else {
        const data = await response.json()
        console.log(data)
        if (data) {
          localStorage.setItem('token', data.token)
          localStorage.setItem('role', data.user.role)
          localStorage.setItem('user', data.user)
          // window.location.href = 'http://localHost:3001'
        }
      }
    } catch (error) {
      setErrors({ api: 'Network error. Please try again later.' })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className='auth-container'>
      <h1 className='auth-title'>Sign In</h1>
      <p className='auth-subtitle'>Just some details to get you in!</p>
      <form className='auth-form' onSubmit={handleSubmit}>
        {errors.api && <p className='error'>{errors.api}</p>}

        <input
          type='email'
          name='email'
          placeholder='Email Address'
          className='auth-input'
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className='error'>{errors.email}</p>}

        <input
          type='password'
          name='password'
          placeholder='Password'
          className='auth-input'
          value={formData.password}
          onChange={handleChange}
        />
        {errors.password && <p className='error'>{errors.password}</p>}

        <button type='submit' className='auth-button' disabled={isSubmitting}>
          {isSubmitting ? 'Signing In...' : 'Sign In'}
        </button>
      </form>
      <p className='auth-footer'>
        Don’t have an account? <Link to='/signup'>Sign Up</Link>
      </p>
    </div>
  )
}

export default SignIn
