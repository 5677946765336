import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './index.css'
import { signupApi } from '../../Apis'

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    role: '',
    levelOfOperator: '',
    branch: '',
    transitionDate: '',
    city: '',
    state: ''
  })

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  // Update form values
  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  // Validate form
  const validateForm = () => {
    const newErrors = {}
    Object.keys(formData).forEach(field => {
      if (!formData[field] && field !== 'transitionDate') {
        newErrors[field] = 'This field is required'
      }
    })
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address'
    }
    if (formData.password && formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  // Handle form submission
  const handleSubmit = async e => {
    e.preventDefault()
    if (!validateForm()) return

    setIsSubmitting(true)
    try {
      console.log(formData)
      const response = await fetch(signupApi, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      })

      if (!response.ok) {
        const errorData = await response.json()
        setErrors({ api: errorData.message || 'Something went wrong' })
      } else {
        const data = await response.json()
        console.log(response)
        localStorage.setItem('token', data.token)
        localStorage.setItem('role', data.user.role)
        localStorage.setItem('user', data.user)
        navigate('/signin')
      }
    } catch (error) {
      setErrors({ api: 'Network error. Please try again later.' })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className='auth-container'>
      <h1 className='auth-title'>Sign Up</h1>
      <form className='auth-form' onSubmit={handleSubmit}>
        {errors.api && <p className='error'>{errors.api}</p>}
        <input
          type='text'
          name='firstName'
          placeholder='First Name'
          className='auth-input'
          value={formData.firstName}
          onChange={handleChange}
        />
        {errors.firstName && <p className='error'>{errors.firstName}</p>}

        <input
          type='text'
          name='lastName'
          placeholder='Last Name'
          className='auth-input'
          value={formData.lastName}
          onChange={handleChange}
        />
        {errors.lastName && <p className='error'>{errors.lastName}</p>}

        <input
          type='email'
          name='email'
          placeholder='Email Address'
          className='auth-input'
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className='error'>{errors.email}</p>}

        <input
          type='text'
          name='phoneNumber'
          placeholder='Phone Number'
          className='auth-input'
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        {errors.phoneNumber && <p className='error'>{errors.phoneNumber}</p>}

        <input
          type='password'
          name='password'
          placeholder='Password'
          className='auth-input'
          value={formData.password}
          onChange={handleChange}
        />
        {errors.password && <p className='error'>{errors.password}</p>}

        <select
          name='role'
          value={formData.role}
          onChange={handleChange}
          className='auth-input'
        >
          <option value='' disabled>
            Select Your Role
          </option>
          <option value='operator'>Operator</option>
          <option value='concierge'>Concierge</option>
          <option value='peer Ambassador'>Peer Ambassador</option>
          <option value='service Partner'>Service Partner</option>
        </select>
        {errors.role && <p className='error'>{errors.role}</p>}

        <input
          type='text'
          name='levelOfOperator'
          placeholder='Level of Operator'
          className='auth-input'
          value={formData.levelOfOperator}
          onChange={handleChange}
        />
        {errors.levelOfOperator && (
          <p className='error'>{errors.levelOfOperator}</p>
        )}

        <input
          type='text'
          name='branch'
          placeholder='Branch'
          className='auth-input'
          value={formData.branch}
          onChange={handleChange}
        />
        {errors.branch && <p className='error'>{errors.branch}</p>}
        <label htmlFor='transitionDate'>Transition Date :</label>
        <input
          type='date'
          name='transitionDate'
          placeholder='transition date'
          className='auth-input'
          value={formData.transitionDate}
          onChange={handleChange}
        />

        <input
          type='text'
          name='city'
          placeholder='City'
          className='auth-input'
          value={formData.city}
          onChange={handleChange}
        />
        {errors.city && <p className='error'>{errors.city}</p>}

        <input
          type='text'
          name='state'
          placeholder='State'
          className='auth-input'
          value={formData.state}
          onChange={handleChange}
        />
        {errors.state && <p className='error'>{errors.state}</p>}

        <button type='submit' className='auth-button' disabled={isSubmitting}>
          {isSubmitting ? 'Signing Up...' : 'Sign Up'}
        </button>
      </form>
      <p className='auth-footer'>
        Already have an account? <Link to='/signin'>Sign In</Link>
      </p>
    </div>
  )
}

export default SignUp
